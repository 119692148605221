import { FC, PropsWithChildren } from "react";

import Head from "next/head";

import PageLayout from "@busbud/design-system-components/dist/Layout";

import { FormController } from "@components/forms/formController";
import { CommonProps } from "@services/common";

import { Footer } from "./footer";
import { Navigation } from "./navigation";

export interface LayoutProps extends CommonProps {
  searchInFooter?: boolean;
}

export const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  children,
  categories,
  translations,
  searchInFooter,
  sharedFormLabels,
  forms,
}) => {
  return (
    <PageLayout gap={0} yMargin={0}>
      <FormController forms={forms} formLabels={sharedFormLabels} />

      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Navigation translations={translations} />
      <main>{children}</main>
      <Footer
        categories={categories}
        translations={translations}
        withSearch={searchInFooter}
      />
    </PageLayout>
  );
};
