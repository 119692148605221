import { FC } from "react";

import Hidden from "@busbud/design-system-components/dist/Hidden";
import Stack from "@busbud/design-system-components/dist/Stack";

import { HomeLink } from "./home-link";
import { MenuDesktop } from "./menu-desktop";
import { MenuMobile } from "./menu-mobile";

export const NAV_BAR_HEIGHT = { xs: 72, sm: 104 };

type Props = {
  translations: NavigationTranslationsFragment;
};

export const Navigation: FC<Props> = ({ translations }) => {
  return (
    <Stack
      justifyContent="space-between"
      px={{ xs: 3, md: 5, lg: 8, xl: 9 }}
      py={{ xs: 1.5, md: 4 }}
      alignItems="center"
      component="nav"
      height={NAV_BAR_HEIGHT}
      position="absolute"
      top={0}
      left={0}
      right={0}
    >
      <HomeLink />
      <Hidden smUp>
        <MenuMobile translations={translations} />
      </Hidden>
      <Hidden xsDown>
        <MenuDesktop translations={translations} />
      </Hidden>
    </Stack>
  );
};
