import { FC, useEffect, useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { Divider } from "@busbud/design-system-components";
import Drawer from "@busbud/design-system-components/dist/Drawer";
import ButtonIcon from "@busbud/design-system-components/dist/IconButton";
import { BusbudMD, CrossLG } from "@busbud/design-system-components/dist/Icons";
import MenuLG from "@busbud/design-system-components/dist/Icons/Menu/LG";
import Label from "@busbud/design-system-components/dist/Label";
import Stack from "@busbud/design-system-components/dist/Stack";
import makeStyles from "@busbud/design-system-components/dist/styles/makeStyles";

import { localesToPubwebLocale } from "../constants/i18n";

import { HomeLink } from "./home-link";
import { LocalePicker } from "./locale-picker";

const useStyles = makeStyles(
  ({ busbud: { breakpoints, spacing, styles, colors } }) => {
    return {
      drawer: {
        maxWidth: "none!important",
        width: "100%",
        [breakpoints.up("sm")]: {
          maxWidth: "50%!important",
        },
      },
      link: {
        display: "flex",
        justifyContent: "flex-start",
        padding: spacing(2, 1.5, 2, 2),
      },
      menuCategory: {
        backgroundColor: colors.background.secondary.opaque,
        padding: spacing(1, 1.5),
        borderRadius: `${styles.rounding.pill} 0 0 ${styles.rounding.pill}`,
      },
    };
  }
);

type Props = {
  translations: NavigationTranslationsFragment &
    LocalePickerTranslationsFragment;
};

export const MenuMobile: FC<Props> = ({ translations }) => {
  const classes = useStyles();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { locale } = useRouter();
  const busbud_locale =
    localesToPubwebLocale[locale as keyof typeof localesToPubwebLocale] || "en";

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = isMenuVisible ? "hidden" : "unset";
    }
  }, [isMenuVisible]);

  return (
    <>
      <ButtonIcon
        size="lg"
        color="tertiary"
        variant="text"
        onClick={() => setIsMenuVisible(true)}
      >
        <MenuLG />
      </ButtonIcon>
      <Drawer
        open={isMenuVisible}
        onClose={() => setIsMenuVisible(false)}
        anchor="right"
        classes={{
          box: classes.drawer,
        }}
      >
        <Stack
          justifyContent="space-between"
          px={{ xs: 2, md: 5, lg: 8, xl: 9 }}
          py={{ xs: 1.5, md: 4 }}
          alignItems="center"
        >
          <HomeLink />
          <ButtonIcon
            size="lg"
            color="tertiary"
            variant="text"
            onClick={() => setIsMenuVisible(false)}
          >
            <CrossLG />
          </ButtonIcon>
        </Stack>
        <Stack direction="column" pl={2}>
          <Label
            size="xs"
            fontWeight="bold"
            color="text.tertiary.opaque"
            className={classes.menuCategory}
          >
            {translations.navItemUsefulLinks}
          </Label>
          <Link
            prefetch={false}
            href={`https://www.busbud.com/${busbud_locale}`}
          >
            <Label
              size="lg"
              fontWeight="bold"
              startIcon={<BusbudMD color="icon.primary.opaque" />}
              className={classes.link}
            >
              {translations.navItemBusbud}
            </Label>
          </Link>
          <Divider />
          <LocalePicker
            onLocaleChange={() => setIsMenuVisible(false)}
            translations={translations}
          >
            <LocalePicker.MenuItem className={classes.link} />
          </LocalePicker>
          <Divider />
        </Stack>
      </Drawer>
    </>
  );
};
