import { useEffect, useState } from "react";

import type { BreakpointKeys } from "@busbud/design-system-components/dist/types/tokens/breakpoints";
import { useTheme } from "@busbud/design-system-components/dist/styles";

export const useBreakpoint = (breakpoint: BreakpointKeys) => {
  const [matches, setMatches] = useState(false);
  const { breakpoints } = useTheme();

  const handleChange = (e: MediaQueryListEvent) => {
    setMatches(e.matches);
  };

  useEffect(() => {
    const mediaQuery = breakpoints.up(breakpoint);
    const maxWidth = mediaQuery.substring(mediaQuery.indexOf("("));

    const media = window.matchMedia(maxWidth);
    media.addEventListener("change", handleChange);

    if (media.matches) {
      setMatches(true);
    }

    return () => media.removeEventListener("change", handleChange);
  }, []);

  return matches;
};
