import { FC, ReactNode } from "react";

import LayoutSection from "@busbud/design-system-components/dist/Layout/LayoutSection";
import Stack from "@busbud/design-system-components/dist/Stack";
import { makeStyles } from "@busbud/design-system-components/dist/styles";

import { NAV_BAR_HEIGHT } from "./navigation";
import { CURVE_HEIGHT } from "./section";

const useStyles = makeStyles(
  ({ busbud: { spacing, breakpoints, gradients } }) => ({
    header: {
      background: gradients.blueGreen,
      padding: spacing(0, 3),
      paddingTop: NAV_BAR_HEIGHT.xs,
      paddingBottom: `calc(${spacing(3)} + ${CURVE_HEIGHT})`,
      [breakpoints.up("sm")]: {
        paddingTop: `calc(${NAV_BAR_HEIGHT.sm}px + ${spacing(2)})`,
        paddingBottom: `calc(${spacing(10)} + ${CURVE_HEIGHT})`,
      },
    },
  })
);

type Props = {
  background?: string;
  children: ReactNode[];
};

export const Header: FC<Props> = ({ children, background }) => {
  const classes = useStyles();

  return (
    <LayoutSection
      className={classes.header}
      style={{ background: background }}
      gutters={2}
    >
      <Stack
        component="header"
        direction="column"
        alignItems="center"
        spacing={{ xs: 2, sm: 3.5 }}
      >
        {children}
      </Stack>
    </LayoutSection>
  );
};
